import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { AllRoutesComponent } from './pages/all-routes/all-routes.component';
import { HomeComponent } from './pages/home/home.component';
import { RouteDetailComponent } from './pages/route-detail/route-detail.component';
import { TariffComponent } from './pages/tariff/tariff.component';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "outstation-cabs",
    component: AllRoutesComponent
  },
  {
    path: "outstation-cabs/:routeUrl",
    component: RouteDetailComponent
  },
  {
    path: "about",
    component: AboutComponent
  },
  {
    path: "tariff",
    component: TariffComponent
  },

  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
