<div class="wo-aboutbannerwrap">
    <div class="wo-aboutbannerbg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 596.99">
            <path class="path1" d="M1923,0V456.68C963,456.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
            <path class="path2" d="M1923,0V436.68C963,436.68,963,597,3,597V0H1923Z" transform="translate(-3)"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="wo-aboutbanner">
                    <div class="wo-aboutbanner__title">
                        <h2><span>South India's Largest Intercity
                            </span><em> and Local Cab Services</em></h2>
                    </div>
                    <div class="wo-aboutbanner__description">
                        <p>
                            We are {{settings.siteName}} Car Rentals, an online cab booking aggregator, providing
                            customers with
                            reliable and premium Intercity and Local car rental services. Over the last decade, we are
                            uniquely placed as the largest chauffeur driven car rental company in India in terms of
                            geographical reach.
                        </p>
                        <br>

                        <table>
                            <tbody>
                                <tr>
                                    <th> Car Type</th>
                                    <th> One Way</th>
                                    <th> Round Trip</th>
                                </tr>
                                <tr *ngFor="let car of cars">
                                    <td>{{car.carType}}</td>
                                    <td>{{car.oneway}} ₹ / KM</td>
                                    <td>{{car.roundtrip}} ₹ / KM</td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <p>
                            Toll fees,Inter-State Permit charges (if any) are extra. <br>

                            Drop Trips-Driver Bata Rs.300.- Waiting Charges Rs.100 per hour. <br>

                            Drop Trips-Minimum running must be 130kms per day. <br>

                            Round Trips-Driver Bata Rs.300/- per day. <br>

                            Round Trips-Minimum running must be 250kms per day.For Bengaluru it is minimum 300kms per
                            day. <br>

                            Hill station charges - Rs.300 <br>

                            1 day means 1 calender day (from midnight 12 to Next Midnight 12) <br>
                        </p>


                    </div>

                </div>
            </div>
        </div>
    </div>
</div>