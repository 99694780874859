<!-- BANNER START -->
<div id="booking" class="wo-mainbanner-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="wo-banner">
          <div class="wo-banner__formwrap">

            <div class="wo-bannertabs-wrap tab-content">
              <div class="wo-bannertabs-content tab-pane active fade show" id="wt-skills">

                <app-outstation></app-outstation>
              </div>

            </div>
          </div>


          <div id="wo-banner__silder" class="wo-banner__silder">
            <figure>
              <img src="assets/images/index/banner/img-01.jpg" alt="img description">
              <figcaption class="wo-slidercontent">
                <div class="wo-slidercontent__title">
                  <h1>About<span>{{settings.siteName}}</span>
                  </h1>

                </div>
                <p>At Pravinn Cabs, we take immense pride in being your premier choice for hassle-free and comfortable
                  travel throughout the beautiful landscapes of Tamil Nadu. With a commitment to exceptional service and
                  unwavering reliability, we bring you a seamless travel experience that leaves you with nothing but
                  delightful memories.



                </p>

                <ul class="wo-slidercontent__featured">
                  <li>
                    <img src="assets/images/index/vehicles-parts/img-01.jpg" alt="img description">
                    <h4>Clean Car </h4>
                  </li>
                  <li>
                    <img src="assets/money.png" alt="img description">
                    <h4>Transparent Billing
                    </h4>
                  </li>
                  <li>
                    <img src="assets/images/index/featured/img-03.png" alt="img description">
                    <h4>Reliable Service
                    </h4>
                  </li>
                  <li>
                    <img src="assets/driver.png" alt="img description">
                    <h4>Courteous Chauffeurs
                    </h4>
                  </li>
                  <li>
                    <img src="assets/toll.png" alt="img description">
                    <h4>Roadtrip Experts
                    </h4>
                  </li>
                </ul>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- BANNER END -->


<section class="about type_one">
  <div class="container">
    <div class="row">

      <div class="col-lg-6 mt-5">
        <div class="heading tp_one  icon_dark">

          <h3 class="mb-3 text-center">Drop Taxi Tariff
          </h3>
        </div>
        <div class="about_content">
          <table style="width:100%" colspan="2">
            <tbody>
              <tr>
                <th>Vehicle Type</th>
                <th>One Way</th>
                <th>Round Trip</th>
              </tr>
              <tr *ngFor="let car of cars">
                <td>{{car.carType}}</td>
                <td>Rs.{{car.oneway}}/KM</td>
                <td>Rs.{{car.roundtrip}}/KM</td>
              </tr>

            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="about type_one">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="heading tp_one  icon_dark">
          <h3 class="mb-3 text-center">Points before ur travel
          </h3>

        </div>
        <div class="about_content">
          <h4> Any Sedan</h4>
          <p class="description">

            Swift Dzire Toyota Etios Tata Zest Hyundai Xcent Nissan sunny anyone will be assigned

          </p>

          <h4>Any SUV
          </h4>

          <p class="description">Innova Xylo Ertiga any one will be assigned

          </p>

          <ul class="desc">
            <li>Maximum 4 passenger capacity for Sedan</li>
            <li>Maximum 7 passenger capacity for SUV</li>

            <li>Minimum 130km coverage for one way trip</li>
            <li> Minimum 250km coverage for round trip.</li>
            <li>One day Round trip close by 11pm at ur pickup </li>
            <li>Waiting charges Rs.150 per hour in between ur trip.</li>
            <li>Food break, tea break, & rest room no waiting chrgs.</li>
            <li>Cancellation charges Rs.300 after driver arrived.</li>
            <li>Luggage load in carrier Rs.300 if Mention in QT.</li>
            <li>Note down starting and ending km is must.</li>
            <li> Permit charges and Driver Bata is same as quoted.</li>
            <li>Toll charges will be paid thru fastag by Driver.</li>
            <li>
              Payment  transfer by online or by cash.
            </li>
            <li>
              EOT calculated running km, Toll by fastag & rest all.
            </li>
            <li> Toll fees, interstate Permit, Hill charges pet charges waiting charges (if any) are
              extra
            </li>
            <li>For Bangalore minimum 300km per day in Round trip
            </li>
            <li>1 day means 1 calendar day(from midnight 12 to next midnight 12)
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</section>




<!-- MAIN START -->
<main class="wo-main overflow-hidden">
  <!-- CATEGORY START -->
  <section class="wo-main-section">
    <div class="container">
      <div class="row text-left">
        <div class="col-lg-12">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <h2>Welcome to Pravinn Cabs - Your Trusted Travel Partner in Tamil Nadu!</h2>
            </div>
            <div class="wo-sectionhead__description" style="text-align: left;">
              <p>
                Embark on a journey of discovery and comfort with Pravinncabs, as we unveil a selection of our special
                routes that showcase the rich tapestry of South India's diverse landscapes, cultures, and history. From
                the tranquil shores of Pondicherry to the vibrant heart of Chennai, and from the ancient allure of
                Mahabalipuram to the spiritual haven of Auroville, our routes are designed to offer you an unforgettable
                travel experience.


              </p>
              <section class="mt-2">
                <h2>Our Top Service Routes:</h2>
                <ul style="text-align: left;">
                  <li>Pondy: Immerse yourself in the vibrant culture of Pondicherry.</li>
                  <li>Mahabalipuram: Explore the ancient wonders of Mahabalipuram.</li>
                  <li>Vellore: Experience the grandeur of Vellore and its historical forts.</li>
                  <li>Pichavaram: Navigate the serene mangrove forests of Pichavaram.</li>
                  <li>Chidambaram: Discover the spiritual aura of Chidambaram and its Nataraja Temple.</li>
                  <li>Cuddalore: Enjoy the beaches and temples of Cuddalore.</li>
                  <li>Villupuram: Explore the cultural diversity of Villupuram.</li>
                  <li>Thirupati: Embark on a divine journey to Thirupati and the Tirumala Temple.</li>
                  <li>Bangalore: Experience the tech and culture hub of Bangalore.</li>
                  <li>Chennai Airport, Central Railway Station, Egmore: Seamless transfers.</li>
                  <li>Trichy: Discover the history and temples of Trichy.</li>
                </ul>
              </section>

              <section class="mt-3" style="text-align: left;">
                <h2>Pondicherry Local Sightseeing:</h2>
                <p>Explore the enchanting corners of Pondicherry with our local sightseeing services. Our knowledgeable
                  drivers
                  will guide you through the captivating French Quarter, the Auroville spiritual community, the
                  Promenade Beach,
                  and more. Whether you wish to immerse yourself in history or simply enjoy the coastal breeze, Pravinn
                  Cabs
                  is here to make your local exploration effortless and memorable.</p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- CATEGORY END -->
  <!-- Spare Parts Start -->
  <section class="wo-main-section wo-spareparts-section">
    <span class="wo-spareparts-section__bg"></span>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <h2>Outstation <em> Taxi Service</em></h2>
            </div>
            <div class="wo-sectionhead__description">
              <p>Planning a weekend getaway? Our outstation cab services will help you explore the best destinations</p>
            </div>
          </div>
        </div>
        <div class="wo-spareparts">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/images/index/vehicles-parts/img-01.jpg" alt="img description">
              <h3>Outstation Taxi</h3>
              <a routerLink="/outstation-cabs">Show all</a>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/taxi-stop.png" alt="img description">
              <h3>One way cabs</h3>
              <a routerLink="/outstation-cabs">One way cabs</a>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/images/index/vehicles-parts/img-03.jpg" alt="img description">
              <h3>Corporate Car Rental</h3>
              <a routerLink="/outstation-cabs">Show all</a>
            </div>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="wo-sparepart">
              <img src="assets/airport.png" alt="img description">
              <h3>Airport Taxi</h3>
              <a routerLink="/outstation-cabs">Show all</a>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="wo-sparepart">
                <img src="assets/train-station.png" alt="img description">
                <h3>Railway Station Taxi</h3>
                <a routerLink="/outstation-cabs">Show all</a>
              </div>
            </div> -->
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="wo-sparepart">
                <img src="assets/rental-car.png" alt="img description">
                <h3>Local Car Rentals</h3>
                <a routerLink="/outstation-cabs">Show all</a>
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </section>
  <!-- Spare Parts END -->
  <!-- Vehicles Featured Start -->
  <section class="wo-main-section">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="wo-sectionhead">
            <div class="wo-sectionhead__title">
              <span>See The Market Trend With</span>
              <h2>Popular Outstation <em>Drop Taxi Routes</em></h2>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div *ngFor="let place of places[0].routes" class="wo-vehiclesinfos">
            <figure class="wo-vehicles-img">
              <img src="{{place.image}}" alt="img description">
              <figcaption>
                <div class="wo-tags">
                  <a class="wo-tag-photos"><i class="ti-image"></i>25</a>
                </div>
              </figcaption>
            </figure>
            <div class="wo-vehiclesinfo">
              <div class="wo-tags">
                <a class="wo-tag-featured">Featured</a>
              </div>
              <div class="wo-vehicles__tags">
                <a>Sedan</a>
              </div>
              <div class="wo-vehiclesinfo__title">
                <h3><a href="#booking">{{place.origin}} to {{place.destination}}</a></h3>
                <address><i class="ti-location-pin"></i>{{place.origin}} to {{place.destination}}
                </address>
              </div>
              <ul class="wo-vehiclesinfo__list">
                <li><span>Car Type<em>Sedan</em></span></li>
                <li><span>Total Distance<em>{{place.distance}}</em></span></li>
                <li><span>Total Duration <em>{{place.duration}} </em> </span></li>
                <li><span>Rate Per KM<em>{{cars[0]?.oneway | currency: 'INR'}}</em></span></li>
              </ul>
            </div>
            <div class="wo-contectinfo">
              <div class="wo-contectinfo__price">
                <h4><em>One Way </em><sup>₹</sup>{{place.estimation}}</h4>
                <span>Book Chennai to Madurai Cabs at lowest price ₹6094 from {{settings.siteName}}. Get best deals for
                  {{place.origin}} to
                  {{place.destination}} car rentals. Compare from variety of car types like Sedan, Suv</span>

                <div class="wo-header-btns">
                  <a href="tel:{{settings.phoneNo}}" class="wo-contectnum"><i class="ti-mobile"></i>Call Now</a>
                  <a href="https://wa.me/{{settings.whatsapp}}" class="wo-btn wp">
                    <i class="fab fa-whatsapp"></i>Whatsapp Us</a>
                </div>

                <a href="#booking" class="wo-btn">Book Now</a>

              </div>
            </div>
          </div>

          <div class="wo-sectionbtns">
            <a routerLink="/outstation-cabs" class="wo-btn">Browse All</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="wo-fjoinnow" style="margin-top: 30px;">
    <div class="wo-fjoinnow__title">
      <h4>Book Today Get <span> 20% Discount on all Bookings</span></h4>
    </div>
    <div class="wo-fjoinnow__info">
      <p> Hire outstation and AC cab with attractive rates, clean & luxury cars, well mannered drivers &
        Transparent billing. Price starts Rs. 9/Km.</p>
    </div>
    <div class="wo-fjoinnow__btn">
      <a href="#booking" class="wo-btn">Book Now</a>
    </div>
  </div>

</main>
<!-- MAIN END -->