<!-- FOOTER START -->
<footer class="wo-footer-wrap">
    <img src="assets/images/footer/shape.png" class="wo-footershape" alt="img description">
    <div class="wo-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wo-fpostwrap">
              <div class="wo-fpostwrap__title">
                <h4>Planning for outstation trips?</h4>
                <h4>Its Easy Just <span>Search, Book & Relax</span></h4>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="wo-fpost">
                    <img src="assets/images/footer/img-03.jpg" alt="img description">
                    <h4>Best Price Guaranteed</h4>
                    <span>Best Price Guarantee is a no-questions-asked price assurance by {{settings.siteName}} for all
                      your
                      outstation cab bookings. </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="wo-fpost">
                    <img src="assets/images/footer/img-02.jpg" alt="img description">
                    <h4>Quality of Vehicles</h4>
                    <span>We have sanitized cars with healthy drivers who does regular basic health checks to ensure the
                      safety of our customers.
  
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="wo-fpost">
                    <img src="assets/images/footer/img-01.jpg" alt="img description">
                    <h4>Transprent Billing</h4>
                    <span> When you book a ride with us, we'll present you with an all-inclusive quote, which includes
                      tolls and taxes. The price you see is the price you pay.</span>
                  </div>
                </div>
              </div>
            </div>
  
          </div>

         
       
        </div>

        <div class="sub-footer">
          <div class="container">
              <div class="subfooter-nav">
                  <div class="row city">
  
                      <div class="col-md-4">
                          <div class="foot-link-box">
                              <h4>Chennai Drop Taxi</h4>
                              <ul>
                                  <li>
                                      <a routerLink="/outstation-cabs/chennai-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Chennai to
                                          Coimbatore Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/chennai-to-bangalore-taxi"><i class="fas fa-angle-double-right"></i>Chennai to Bangalore
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/chennai-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Chennai to Erode
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/chennai-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Chennai to Madurai
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/chennai-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Chennai to
                                          Pondicherry Taxi</a>
                                  </li>
  
                              </ul>
                          </div>
                      </div>
  
                      <div class="col-md-4">
                          <div class="foot-link-box">
                              <h4>Coimbatore Taxi</h4>
                              <ul>
                                  <li>
                                      <a routerLink="/outstation-cabs/coimbatore-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Coimbatore to
                                          Chennai Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/coimbatore-to-bangalore-taxi"><i class="fas fa-angle-double-right"></i>Coimbatore to
                                          Bangalore Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/coimbatore-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Coimbatore to
                                          Madurai Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/coimbatore-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Coimbatore to
                                          Erode Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/coimbatore-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Coimbatore to
                                          Pondicherry Taxi</a>
                                  </li>
  
                              </ul>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="foot-link-box">
                              <h4>Bangalore Drop Taxi</h4>
                              <ul>
                                  <li>
                                      <a routerLink="/outstation-cabs/bangalore-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Bangalore to
                                          Chennai Taxi </a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/bangalore-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Bangalore to
                                          Coimbatore Taxi </a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/bangalore-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Bangalore to
                                          Madurai Taxi </a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/bangalore-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Bangalore to Erode
                                          Taxi </a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/bangalore-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Bangalore to
                                          Pondicherry Taxi </a>
                                  </li>
  
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="row city mt-3">
                      <div class="col-md-4">
                          <div class="foot-link-box">
                              <h4>Erode Drop Taxi</h4>
                              <ul>
                                  <li>
                                      <a routerLink="/outstation-cabs/erode-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Erode to Chennai
                                          taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/erode-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Erode to Coimbatore
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/erode-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Erode to Madurai drop
                                          taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/erode-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Erode to Pondicherry
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/erode-to-banagalore-taxi"><i class="fas fa-angle-double-right"></i>Erode to Bangalore
                                          Taxi</a>
                                  </li>
  
                              </ul>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="foot-link-box">
                              <h4>Madurai Drop Taxi</h4>
                              <ul>
                                  <li>
                                      <a routerLink="/outstation-cabs/madurai-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Madurai to Chennai
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/madurai-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Madurai to
                                          Coimbatore Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/madurai-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Madurai to Erode
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/madurai-to-bangalore-taxi"><i class="fas fa-angle-double-right"></i>Madurai to Bangalore
                                          Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/madurai-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Madurai to
                                          Pondicherry Taxi</a>
                                  </li>
  
                              </ul>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="foot-link-box">
                              <h4>Pondicherry Drop Taxi</h4>
                              <ul>
                                  <li>
                                      <a routerLink="/outstation-cabs/pondicherry-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Pondicherry to
                                          Chennai Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/pondicherry-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Pondicherry to
                                          Coimbatore Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/pondicherry-to-bangalore-taxi"><i class="fas fa-angle-double-right"></i>Pondicherry to
                                          Bangalore Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/pondicherry-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Pondicherry to
                                          Erode Taxi</a>
                                  </li>
                                  <li>
                                      <a routerLink="/outstation-cabs/pondicherry-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Pondicherry to
                                          Madurai Taxi</a>
                                  </li>
  
                              </ul>
                          </div>
                      </div>
  
  
  
  
                  </div>

                  <div class="row city mt-3">
                    <div class="col-md-4">
                        <div class="foot-link-box">
                            <h4>Salem Drop Taxi</h4>
                            <ul>
                                <li>
                                    <a routerLink="/outstation-cabs/salem-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Salem to Chennai
                                        taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/salem-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Salem to Coimbatore
                                        Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/salem-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Salem to Madurai drop
                                        taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/salem-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Salem to Pondicherry
                                        Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/salem-to-banagalore-taxi"><i class="fas fa-angle-double-right"></i>Salem to Bangalore
                                        Taxi</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="foot-link-box">
                            <h4>Trichy Drop Taxi</h4>
                            <ul>
                                <li>
                                    <a routerLink="/outstation-cabs/trichy-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Trichy to Chennai
                                        Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/trichy-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Trichy to
                                        Coimbatore Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/trichy-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Trichy to Erode
                                        Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/trichy-to-bangalore-taxi"><i class="fas fa-angle-double-right"></i>Trichy to Bangalore
                                        Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/trichy-to-pondicherry-taxi"><i class="fas fa-angle-double-right"></i>Trichy to
                                        Pondicherry Taxi</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="foot-link-box">
                            <h4>Vellore Drop Taxi</h4>
                            <ul>
                                <li>
                                    <a routerLink="/outstation-cabs/vellore-to-chennai-taxi"><i class="fas fa-angle-double-right"></i>Vellore to
                                        Chennai Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/vellore-to-coimbatore-taxi"><i class="fas fa-angle-double-right"></i>Vellore to
                                        Coimbatore Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/vellore-to-bangalore-taxi"><i class="fas fa-angle-double-right"></i>Vellore to
                                        Bangalore Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/vellore-to-erode-taxi"><i class="fas fa-angle-double-right"></i>Vellore to
                                        Erode Taxi</a>
                                </li>
                                <li>
                                    <a routerLink="/outstation-cabs/vellore-to-madurai-taxi"><i class="fas fa-angle-double-right"></i>Vellore to
                                        Madurai Taxi</a>
                                </li>

                            </ul>
                        </div>
                    </div>




                </div>
              </div>
          </div>
      </div>
      </div>
    </div>
    <div class="wo-footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wo-footernav-content">
              <p class="wo-copyrights">Copyrights © 2023 by <a>{{settings.siteName}}</a> All
                Rights Reserved.</p>
              <nav class="wo-footernav">
                <ul>
                  <li><a href="https://www.calvincareinfotech.com">Developed By Calvin Care</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER END -->