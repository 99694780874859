import { Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'limit'
})
@Injectable()
export class Limit {

  constructor() {}
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
   }
}