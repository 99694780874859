import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CanonicalService } from 'src/app/canonical.service';
import { CARS } from 'src/app/cars';
import { OutstationService } from 'src/app/outstation.service';
import { SITE_SETTINGS } from 'src/app/site-settings';

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.css']
})
export class RouteDetailComponent implements OnInit {
  origin;
  destination;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  totalDistance;
  totalDuration;
  showDirection;
  cars = CARS;
  estimations = [];
  settings = SITE_SETTINGS;
  title;

  constructor(private outstationService: OutstationService, private activeRoute: ActivatedRoute,  private canonicalService: CanonicalService, private titleService: Title,  private metaTagService: Meta) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
   
    let routeUrl = this.activeRoute.snapshot.paramMap.get('routeUrl').split('-');
    this.origin = routeUrl[0].charAt(0).toUpperCase() + routeUrl[0].slice(1);
    this.destination = routeUrl[2].charAt(0).toUpperCase() + routeUrl[2].slice(1);
    (this.origin && this.destination) ? this.showDirection = true: this.showDirection = false;

    this.title = `Book ${this.origin} to ${this.destination} Outstation Taxi at 10 ₹ at an affordable price`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Hire ${this.origin} to ${this.destination} outstation taxi with attractive rates. We offer clean, well mannered drivers & transparent billing.` }
    );
  }

  onResponses(event) {
    this.totalDistance = event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "");
    this.totalDuration = event.routes[0].legs[0].duration.text;
    this.getEstimation( this.totalDistance, this.totalDuration )
  }

  getEstimation(totalDistance, totalDuration ) {
    this.estimations = [];
    this.cars.forEach(car => {
      car['oneWaytripEstimation'] = car.oneway * totalDistance + car.onewayDriverFee;
      car['roundTripEstimation'] =  car.roundtrip * totalDistance * 2 + car.roundTripDriverFee;
      car['oneWayDistance'] = totalDistance;
      car['roundTripistance'] = totalDistance * 2;
      car['totalDuration'] = totalDuration;
      this.estimations.push(car);
    });
  }


  

}
