import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CARS } from 'src/app/cars';
import { PLACES, popular_cities, airport_citis } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/site-settings';


@Component({
  selector: 'app-airport',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.css']
})
export class AirportComponent implements OnInit {
  @ViewChild('airportAddressRef', { read: ElementRef }) airportAddressElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  contactFormGroup: FormGroup;
  airportFormGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  cars = CARS;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  requestRide = false;
  rideDetails;
  name;
  phoneNo;
  places = PLACES;
  routeTotalDistance;
  routeTotalDuration;
  displayMaps = false;
  popularCities = popular_cities;
  placeIds = [];
  otherCity = false;
  settings = SITE_SETTINGS;
  estimations = [] as any;
  routeDetails;
  selectedCarType;
  originCityName;
  destinationCityName;
  bookingDetails;
  submitted = false;
  contactSubmitted = false;
  showSpinner = false;
  filteredOptions: Observable<string[]>;
  filteredOptionsDrop: Observable<string[]>;
  airportCitis: string[] = airport_citis;
  airportAddress;
  airportContactSubmitted;
  minDate = new Date();

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private http: HttpClient, private fb: FormBuilder, private ref: ChangeDetectorRef) { }

  ngOnInit() {

    this.contactFormGroup = this.fb.group({
      name: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      ]],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]]
    })

    this.airportFormGroup = this.fb.group({
      city: ['', Validators.required],
      trip: ['Cab From Airport',
        Validators.required],
      address: ['', Validators.required],
      date: [new Date, Validators.required],
      time: ['', Validators.required]
    })

    this.airportFormGroup.get('time').setValue(moment(Date.now()).format('h:mm a'));


    this.mapsAPILoader.load().then(() => {
      const airportAddress = this.airportAddressElementRef?.nativeElement;
      this.addMapEventListener(airportAddress, 'airportAddress');
    });

    this.filteredOptions = this.airportFormGroup.get('city').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value as any))
    );
  }

  get contactValidation() { return this.contactFormGroup.controls; }

  get airportValidation() { return this.airportFormGroup.controls; }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.airportCitis.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  selectAirportCars() {
    this.submitted = true;
    if (this.airportFormGroup.invalid || this.showSpinner) {
      return;
    }

    this.estimations = [];
    this.routeDetails = {
      ...this.airportFormGroup.value,
      distance: this.totalDistance,
      duration: this.totalDuration
    }
    this.getEstimation(this.routeDetails);
    this.stepper.next();
    this.scrollToSectionHook();
  }

  private scrollToSectionHook() {
    const element = document.querySelector('body');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth', block: 'start', inline:
            'nearest'
        });
      }, 250);
    }
  }

  selectedCar($event) {
    this.selectedCarType = $event;
    this.stepper.next();
    this.scrollToSectionHook();
  }

  back() {
    this.stepper.previous();
  }

  bookNowAirportTaxi() {
    this.airportContactSubmitted = true;
    if (this.contactFormGroup.invalid) {
      return;
    }
    this.bookingDetails = {
      ...this.airportFormGroup.value,
      ...this.contactFormGroup.value,
      ...this.selectedCarType,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email,
      'origin': this.bookingDetails.city,
      'destination': this.airportAddress,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': this.bookingDetails.pickupDate,
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.totalDuration,
      'tripType': this.bookingDetails.trip,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `${this.settings.adminEmailSubject} ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
    }

    let smsMessage = `Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${this.settings.siteName}. Your Booking Details:\r\nBooking ID: ${bookingDetails.bookingId}\r\nName: ${bookingDetails.name}\r\nMobile Number: ${bookingDetails.phoneNo}\r\nPickup Location: ${bookingDetails.origin}\r\nDrop Location: ${bookingDetails.destination} \r\nPickup Date/Time: ${bookingDetails.bookedDate}\r\nTotal KM: ${bookingDetails.distance} KM\r\nTotal Trip Fare: ${bookingDetails.estimate} ₹\r\nRate Per KM: ${bookingDetails.ratePerKM} ₹\r\nDriver Batta: ${bookingDetails.driverBatta} ₹\r\nToll, parking, permit extra \r\n For any questions please contact ${this.settings.phoneNo}`

    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let newSMS = `Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${this.settings.siteName}. Your booking is confirmed our representative will call in 10-15 mins. For any question please contact ${this.settings.phoneNo}`


    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));
    this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");

    this.stepper.next();
    this.scrollToSectionHook();

  }

  getEstimation(routes) {
    let distance = (routes.distance <= 130) ? routes.distance = 130 : routes.distance;

    this.cars.forEach(car => {
      car['airportCity'] = routes.city;
      car['tripEstimation'] =  car.oneway * distance + car.onewayDriverFee;
      car['driverBatta'] = car.onewayDriverFee;
      car['ratePerKM'] = car.oneway;
      car['distance'] = routes.distance;
      car['baseFare'] =  car.oneway * distance;
      car['triptype'] = routes.trip;
      car['pickupDate'] = moment(routes.date).format('MM/DD/YYYY');
      car['pickupTime'] = routes.time;
      car['returnDate'] = moment(routes.returnDate).format('MM/DD/YYYY');;
      this.estimations.push(car);
    });
  }

  onResponses(event) {
    this.totalDistance = event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, "");
    this.totalDuration = event.routes[0].legs[0].duration.text;
    this.showSpinner = false;
  }

  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'airportAddress') ? this.airportAddress = place.formatted_address : '';

        if (this.airportAddress) {
          this.showSpinner = true;
          this.showDirection = true;
        }

        this.zoom = 12;
      });
    });
  }

}
