export const tamilnadu_districts = [
'Ariyalur',
'Chengalpattu',
'Chennai',
'Coimbatore',
'Cuddalore',
'Dharmapuri',
'Dindigul',
'Erode',
'Kallakurichi',
'Kanchipuram',
'Kanyakumari',
'Karur',
'Krishnagiri',
'Madurai',
'Nagapattinam',
'Namakkal',
'Nilgiris',
'Perambalur',
'Pudukkottai',
'Ramanathapuram',
'Ranipet',
'Salem',
'Sivaganga',
'Tenkasi',
'Thanjavur',
'Theni',
'Thoothukudi',
'Tiruchirappalli',
'Tirunelveli',
'Tirupathur',
'Tiruppur',
'Tiruvallur',
'Tiruvannamalai',
'Tiruvarur',
'Vellore',
'Viluppuram',
'Virudhunagar',
'Bangalore',
'Pondicherry',
'Tirupati',
];

export const outstation_citis = [
'Chengalpattu',
'Coimbatore',
'Kanchipuram',
'Madurai',
'Vellore',
'Tiruvannamalai',
'Salem',
'Salem',
'Trichy',
'Ariyalur',
'Cuddalore',
'Dharmapuri',
'Dindigul',
'Erode',
'Kallakurichi',
'Kanyakumari',
'Karur',
'Krishnagiri',
'Mayiladuthurai',
'Nagapattinam',
'Namakkal',
'Nilgiris',
'Perambalur',
'Pudukkottai',
'Ramanathapuram',
'Ranipet',
'Sivagangai',
'Tenkasi',
'Thanjavur',
'Theni',
'Thoothukudi',
'Tirupattur',
'Tiruppur',
'Tiruvallur',
'Tiruvarur',
'Viluppuram',
'Virudhunagar',
'Bangalore',
'Pondicherry',
'Tirupati',
]

export const popular_cities = ['Chennai', 'Madurai', 'Tiruchirappalli', 'Coimbatore', 'Tiruppur'];

// export const popular_outstation_routes = [
//    {
//       origin: 'Chennai',
//       destination: 'Coimbatore',
//    },
//    {
//       origin: 'Chennai',
//       destination: 'Madurai',
//    },
//    {
//       origin: 'Chennai',
//       destination: 'Vellore',
//    },
//    {
//       origin: 'Chennai',
//       destination: 'Tiruvannamalai',
//    },
//    {
//       origin: 'Chennai',
//       destination: 'Bangalore',
//    },
//    // {
//    //    origin: 'Madurai',
//    //    destination: 'Ramanadhapuram',
//    // },
//    // {
//    //    origin: 'Tirunelveli',
//    //    destination: 'Chennai',
//    // },
//    // {
//    //    origin: 'Salem',
//    //    destination: 'Tiruchirapalli',
//    // },
//    // {
//    //    origin: 'Coimbatore',
//    //    destination: 'Bangalore',
//    // },
//    // {
//    //    origin: 'Chennai',
//    //    destination: 'Erode',
//    // },

// ];

// export const popular_outstation_other_city_routes = [
//    {
//       origin: 'Madurai',
//       destination: 'Ramanadhapuram',
//    },
//    {
//       origin: 'Tirunelveli',
//       destination: 'Chennai',
//    },
//    {
//       origin: 'Salem',
//       destination: 'Tiruchirapalli',
//    },
//    {
//       origin: 'Coimbatore',
//       destination: 'Bangalore',
//    },
//    // {
//    //    origin: 'Erode',
//    //    destination: 'Chennai',
//    // },
//    {
//       origin: 'Pondicherry',
//       destination: 'Bangalore',
//    }
// ];

export const PLACES = [
   {
      city: 'chennai',
      routes: [
         {
            origin: 'Chennai',
            destination: 'Vellore',
            image: 'https://dharmicverses.com/wp-content/uploads/2021/03/Sri-Lakshmi-Narayani-Golden-Temple.jpg',
            carType: 'Sedan',
            distance: '137.8 Km',
            duration: '3 hr 37 min',
            estimation: ~~(137.8 * 13 + 300),
            url: 'chennai-to-bengaluru-outstation-drop-taxi',
         },
         {
            origin: 'Chennai',
            destination: 'Bangalore',
            image: 'https://www.gosahin.com/go/p/f/1544336428_Bangalore-Palace1.jpg',
            carType: 'Sedan',
            distance: '346.7 km',
            duration: '5 hr 52 min',
            estimation: '4461',
            url: 'chennai-to-bengaluru-outstation-drop-taxi',
         },
         {
            origin: 'Chennai',
            destination: 'Pondicherry',
            image: 'https://www.holidify.com/images/bgImages/PONDICHERRY.jpg',
            carType: 'Sedan',
            distance: '150.7 km',
            duration: '2 hr 36 min',
            estimation: '2112',
            url: 'chennai-to-puducherry-outstation-drop-taxi'
         },
         {
            origin: 'Chennai',
            destination: 'Madurai',
            image: 'https://images.outlookindia.com/public/uploads/articles/2020/8/18/Meenakshi_Amman_Temple,_Madurai.jpg',
            carType: 'Sedan',
            distance: '462.3 km',
            duration: '7 hr 6 min',
            estimation: '5856',
            url: 'chennai-to-madurai-outstation-drop-taxi'
         },
         {
            origin: 'Chennai',
            destination: 'Trichy',
            image: 'https://img.traveltriangle.com/blog/wp-content/uploads/2020/06/cover-Places-To-Visit-In-Trichy_8th-June.jpg',
            carType: 'Sedan',
            distance: '331.8 km',
            duration: '5 hr 8 min',
            estimation: '4284',
            url: 'chennai-to-tiruchirappalli-outstation-drop-taxi'
         },
         {
            origin: 'Chennai',
            destination: 'Coimbatore',
            image: 'https://img.traveltriangle.com/blog/wp-content/uploads/2020/01/CoverCoimbatore-0310.jpg',
            carType: 'Sedan',
            distance: '505.5 km',
            duration: '8 hr 14 min',
            estimation: '6372',
            url: 'chennai-to-coimbatore-outstation-drop-taxi'
         }
      ]
   },
   {
      city: 'bangalore',
      routes: [
         {
            origin: 'Bangalore',
            destination: 'Chennai',
            image: '',
            carType: 'Sedan',
            distance: '347.2 km',
            duration: '6 hr 8 min',
            estimation: '4476',
            url: 'bengaluru-to-chennai-outstation-drop-taxi'
         },
         {
            origin: 'Bangalore',
            destination: 'Pondicherry',
            image: '',
            carType: 'Sedan',
            distance: '310.0 km',
            duration: '7 hr 5 min',
            estimation: '4020',
            url: 'bengaluru-to-puducherry-outstation-drop-taxi'
         },
         {
            origin: 'Bangalore',
            destination: 'Madurai',
            image: '',
            carType: 'Sedan',
            distance: '435.9 km',
            duration: '7 hr 34 min',
            estimation: 12 * parseFloat('435.9 km') + 300,
            url: 'bengaluru-to-madurai-outstation-drop-taxi'
         },
         {
            origin: 'Bangalore',
            destination: 'Trichy',
            image: '',
            carType: 'Sedan',
            distance: '344.0 km',
            duration: '6 hr 20 min',
            estimation: 12 * parseFloat('344.0 km') + 300,
            url: 'bengaluru-to-trichy-outstation-drop-taxi'
         },
         {
            origin: 'Bangalore',
            destination: 'Coimbatore',
            image: '',
            carType: 'Sedan',
            distance: '364.5 km',
            duration: '6 hr 34 min',
            estimation: 12 * parseFloat('364.5 km') + 300,
            url: 'bengaluru-to-coimbatore-outstation-drop-taxi'
         }
      ]
   },
   {
      city: 'pondicherry',
      routes: [
         {
            origin: 'Pondicherry',
            destination: 'Chennai',
            image: '',
            carType: 'Sedan',
            distance: '150.9 km',
            duration: '2 hr 41 min',
            estimation: 12 * parseFloat('150.9 km') + 300,
            url: 'pondicherry-to-chennai-outstation-drop-taxi'
         },
         {
            origin: 'Pondicherry',
            destination: 'Bangalore',
            image: '',
            carType: 'Sedan',
            distance: '308.6 km',
            duration: '6 hr 40 min ',
            estimation: 12 * parseFloat('308.6 km') + 300,
            url: 'pondicherry-to-bangalore-outstation-drop-taxi'
         },
         {
            origin: 'Pondicherry',
            destination: 'Madurai',
            image: '',
            carType: 'Sedan',
            distance: '334.4 km',
            duration: '5 hr 32 min',
            estimation: 12 * parseFloat('334.4 km') + 300,
            url: 'pondicherry-to-madurai-outstation-drop-taxi'
         },
         {
            origin: 'Pondicherry',
            destination: 'Trichy',
            image: '',
            carType: 'Sedan',
            distance: '203.9 km',
            duration: '3 hr 26 min',
            estimation: 12 * parseFloat('203.9 km') + 300,
            url: 'pondicherry-to-trichy-outstation-drop-taxi'
         },
         {
            origin: 'Pondicherry',
            destination: 'Coimbatore',
            image: '',
            carType: 'Sedan',
            distance: '377.6 km',
            duration: '6 hr 39 min',
            estimation: 12 * parseFloat('377.6 km') + 300,
            url: 'pondicherry-to-coimbatore-outstation-drop-taxi'
         }
      ]
   },
   {
      city: 'coimbatore',
      routes: [
         {
            origin: 'Coimbatore',
            destination: 'Chennai',
            image: '',
            carType: 'Sedan',
            distance: '506.6 km',
            duration: '8 hr 37 min',
            estimation: 12 * parseFloat('506.6 km') + 300,
            url: 'coimbatore-to-chennai-outstation-drop-taxi'
         },
         {
            origin: 'Coimbatore',
            destination: 'Bangalore',
            image: '',
            carType: 'Sedan',
            distance: '363.5 km',
            duration: '6 hr 24 min',
            estimation: 12 * parseFloat('363.5 km') + 300,
            url: 'coimbatore-to-bangalore-outstation-drop-taxi'
         },
         {
            origin: 'Coimbatore',
            destination: 'Madurai',
            image: '',
            carType: 'Sedan',
            distance: '216.1 km',
            duration: '4 hr 3 min',
            estimation: 12 * parseFloat('216.1 km') + 300,
            url: 'coimbatore-to-madurai-outstation-drop-taxi'
         },
         {
            origin: 'Coimbatore',
            destination: 'Trichy',
            image: '',
            carType: 'Sedan',
            distance: '216.9 km',
            duration: '4 hr 17 min',
            estimation: 12 * parseFloat('216.9 km') + 300,
            url: 'coimbatore-to-trichy-outstation-drop-taxi'
         },
         {
            origin: 'Coimbatore',
            destination: 'Pondicherry',
            image: '',
            carType: 'Sedan',
            distance: '378.1 km',
            duration: '6 hr 35 min',
            estimation: 12 * parseFloat('378.1 km') + 300,
            url: 'coimbatore-to-pondicherry-outstation-drop-taxi'
         }
      ]
   },
   {
      city: 'madurai',
      routes: [
         {
            origin: 'Madurai',
            destination: 'Chennai',
            image: '',
            carType: 'Sedan',
            distance: '462.5 km',
            duration: '7 hr 32 min',
            estimation: 12 * parseFloat('462.5 km') + 300,
            url: 'madurai-to-chennai-outstation-drop-taxi'
         },
         {
            origin: 'Madurai',
            destination: 'Bangalore',
            image: '',
            carType: 'Sedan',
            distance: '436.2 km',
            duration: '7 hr 32 min',
            estimation: 12 * parseFloat('436.2 km') + 300,
            url: 'madurai-to-bangalore-outstation-drop-taxi'
         },
         {
            origin: 'Madurai',
            destination: 'Coimbatore',
            image: '',
            carType: 'Sedan',
            distance: '217.2 km',
            duration: '4 hr 17 min',
            estimation: 12 * parseFloat('217.2 km') + 300,
            url: 'madurai-to-coimbatore-outstation-drop-taxi'
         },
         {
            origin: 'Madurai',
            destination: 'Trichy',
            image: '',
            carType: 'Sedan',
            distance: '132.2 km',
            duration: '2 hr 3 min',
            estimation: 12 * parseFloat('132.2 km') + 300,
            url: 'madurai-to-trichy-outstation-drop-taxi'
         },
         {
            origin: 'Madurai',
            destination: 'Pondicherry',
            image: '',
            carType: 'Sedan',
            distance: '334.0 km',
            duration: '6 hr 35 min',
            estimation: 12 * parseFloat('378.1 km') + 300,
            url: 'madurai-to-pondicherry-outstation-drop-taxi'
         }
      ]
   },
   {
      city: 'kerala',
      routes: [
         {
            origin: 'Kerala',
            destination: 'Chennai',
            image: '',
            carType: 'Sedan',
            distance: '605.4 km',
            duration: '10 hr 45 min',
            estimation: 12 * parseFloat('605.4 km') + 300,
            url: 'kerala-to-chennai-outstation-drop-taxi'
         },
         {
            origin: 'Kerala',
            destination: 'Bangalore',
            image: '',
            carType: 'Sedan',
            distance: '361.5 km',
            duration: '8 hr 48 min',
            estimation: 12 * parseFloat('361.5 km') + 300,
            url: 'kerala-to-bangalore-outstation-drop-taxi'
         },
         {
            origin: 'Kerala',
            destination: 'Coimbatore',
            image: '',
            carType: 'Sedan',
            distance: '104.0 km',
            duration: '2 hr 23 min',
            estimation: 12 * parseFloat('104.0 km') + 300,
            url: 'kerala-to-coimbatore-outstation-drop-taxi'
         },
         {
            origin: 'Kerala',
            destination: 'Trichy',
            image: '',
            carType: 'Sedan',
            distance: '325.5 km',
            duration: '6 hr 3 min',
            estimation: 12 * parseFloat('325.5 km') + 300,
            url: 'kerala-to-trichy-outstation-drop-taxi'
         },
         {
            origin: 'Kerala',
            destination: 'Pondicherry',
            image: '',
            carType: 'Sedan',
            distance: '477.0 km',
            duration: '8 hr 41 min',
            estimation: 12 * parseFloat('477.0 km') + 300,
            url: 'kerala-to-pondicherry-outstation-drop-taxi'
         }
      ]
   }
]

export const airport_citis = [
   'Chennai',
   'Coimbatore',
   'Madurai',
   'Tiruchirapalli',
   'Tuticorin',
   'Salem',
   'Puducherry',
   'Bangalore'
]



