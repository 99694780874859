import { Component, OnInit } from '@angular/core';
import { CARS } from 'src/app/cars';
import { PLACES, popular_cities } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/site-settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  places = PLACES;
  cars = CARS;
  popularCities = popular_cities;
  settings = SITE_SETTINGS;
  constructor() { }

  ngOnInit(): void {
  }

}
