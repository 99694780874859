import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OutstationComponent } from './components/outstation/outstation.component';
import { AirportComponent } from './components/airport/airport.component';
import { AllRoutesComponent } from './pages/all-routes/all-routes.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { RouteDetailComponent } from './pages/route-detail/route-detail.component';
import { AboutComponent } from './pages/about/about.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { Limit } from './limit';
import { MatSelectModule } from '@angular/material/select';
import { map } from 'rxjs/operators';


export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const id = window.location.pathname.replace(/\//g, "");
  return () => http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
    map(response => {
      config.apiKey = response['mapkey'];
      console.log(response['mapkey']);
      return response;
    })
  ).toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    OutstationComponent,
    AirportComponent,
    AllRoutesComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    RouteDetailComponent,
    AboutComponent,
    TariffComponent,
    Limit
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBODCrJGeL7DH0vzlZbvSq_Xd-wxH_Pisg',
      libraries: ["places"]
    }),
    AgmDirectionModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

//AIzaSyC7ONzuNB-iWxAz8a_ideoKL_VU474-TZs
