<header class="wo-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="wo-header__content">
          <!-- <div class="wo-logo">
                          <a href="index.html"><img src="assets/logo.png" alt="image-description"></a>
                      </div> -->
          <div class="wo-header-nav-center">
            <nav class="navbar-expand-xl">
              <button class="navbar-toggler wo-btn-small" type="button" data-toggle="collapse"
                data-target="#jbMainNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <i class="lnr lnr-menu"></i>
              </button>
              <div id="jbMainNavbar" class="collapse navbar-collapse">
                <ul class="navbar-nav wo-navbarnav">
                  <li>
                    <a routerLink="/">Home</a>

                  </li>
                  <li>
                    <a routerLink="/about">About</a>
                  </li>
                  <li>
                    <a routerLink="/tariff">Tariff</a>
                  </li>
                  <!-- <li>
                      <a href="spareparts-grid-classic.html">Become an Operator</a>
                    </li> -->
                  <!-- <li>
                      <em>new</em>
                      <a href="owner-reviews.html">Corporate Travel</a>
                    </li> -->
                  <li>
                    <a routerLink="/outstation-cabs">Outstation routes</a>
                  </li>


                </ul>
              </div>
            </nav>
            <div class="wo-priceinfo__contact"><a href="tel:{{settings.phoneNo}} "
                class="wo-contectnum"><i class="ti-mobile"></i>Click To Call<span
                 >{{settings.phoneNo}}</span></a><a href="https://wa.me/{{settings.whatsapp}}"
                class="wo-contectnum wo-contectmsg"><i class="fab fa-whatsapp"></i>Chat
                with Us<span> {{settings.whatsapp}} </span></a></div>
          </div>
          <div class="wo-header-user">
            <div class="wo-user">

              <figure class="wo-user">
                <img src="assets/logo.png" alt="image-description">
              </figure>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</header>
<!-- HEADER END -->