<div class="wo-innerbannertwo-wrap bg-color2">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="wo-innerbannertwo">
                    <div class="wo-innerbannertwo__title">
                        <h4><span>South India's first
                            </span>Outstation Cab marketplace.</h4>
                    </div>
                    <div class="wo-innerbannertwo__description">
                        <p>We provide safe, reliable and happy rides at guaranteed best price in the market. You will
                            always have a wide range of offers to choose from. Because we strongly believe that the
                            choice should be always yours.
                        </p>
                    </div>
                    <div class="wo-innerbannertwo__btns">
                        <a routerLink="/" class="wo-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="wo-main-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let city of places">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Outstation cabs in {{city}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                         <ng-container *ngFor="let subCity of places">
                            <h6 class="city" *ngIf="city !== subCity"> 
                              <a (click)="selectedCity(city, subCity )" routerLink="/outstation-cabs/{{city | lowercase}}-to-{{subCity | lowercase}}-outstation-taxi"> {{city}} to {{subCity}} Outstation Cabs</a> 
                            </h6>
                         </ng-container>
                      
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</section>